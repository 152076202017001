<template>
  <v-dialog
    v-model="dialog"
    overlay-color="#000000"
    overlay-opacity="0.54"
    class="mem-dialog"
    :max-width="480"
    :fullscreen="isFullscreen"
  >
    <div class="dialog-error">
      <span class="material-icons close-icon" @click="$emit('input', false)">
        close
      </span>

      <img
        class="warning-icon"
        src="@/assets/warning_rhombus.svg"
        alt="upload_error"
      />
      <div class="error-message">
        <span>{{ $t(errorText) }}</span>
      </div>

      <!-- <div class="dialog-buttons">
        <mem-button>{{ $t("OK") }}</mem-button>
      </div> -->
    </div>
  </v-dialog>
</template>
<script>
export default {
  //   components: {
  //     "mem-button": () => import("@/components/base/BaseButton.vue"),
  //   },
  props: {
    value: Boolean,
    isFullscreen: Boolean,
    errorText: String,
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-error {
  padding: 40px 40px 30px;
  position: relative;
  background-color: #ffffff;
  color: #000000;
  font-size: 16px;
  line-height: 22px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 499px) {
    padding: 20px 20px 16px;
    min-height: 100%;
    justify-content: center;
  }
  .close-icon {
    position: absolute;
    right: 13px;
    top: 13px;
    font-size: 30px;
    @include cursorPointer;
  }
  .warning-icon {
    height: 64px;
    width: 64px;
  }
  .error-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 142px;
    max-width: 280px;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    margin-top: 30px;
    @media screen and (max-width: 499px) {
      min-height: auto;
      margin-top: 32px;
      margin-bottom: 22px;
    }
  }
}
</style>